import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function SplitButton({
  options, isFormChanged, sendAllDocuments, isSending,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={isFormChanged ? 'Перед відправкою документів необхідно зберегти форму' : ''}
        placement="bottom"
      >

        <ButtonGroup
          size="small"
          variant="contained"
          ref={anchorRef}
          disabled={isFormChanged}
          aria-label="Button group with a nested menu"
          sx={{
            height: '33px',
            marginRight: '20px',
          }}
        >
          <Button
            onClick={() => {
              sendAllDocuments();
            }}
            disabled={isSending}
            size="small"
            variant="contained"
            color="primary"
          >
            Відправити все
          </Button>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            sx={{
              width: '8px',
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

      </Tooltip>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options
                    && options.map(item => (
                      <Tooltip
                        key={item.title}
                        title={(item.isDisabled() && !isSending) ? item.tooltipText : ''}
                        placement="left"
                      >
                        <span> {/* Wrapping with span to allow Tooltip to work */}
                          <MenuItem
                            disabled={item.isDisabled()}
                            sx={{ fontSize: '14px' }}
                            onClick={() => {
                              item.action();
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        </span>
                      </Tooltip>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
