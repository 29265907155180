// Core
import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import {
  Form, reduxForm, Field, FieldArray, submit,
} from 'redux-form/immutable';
import { change, touch } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { List, Map } from 'immutable';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import NumberFormat from 'react-number-format';
// UI
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// Icons
import SaveIcons from '@mui/icons-material/Save';
// Parts
import Typography from '@mui/material/Typography';
import TextField from '../../../../components/_Form/TextField/TextField/TextField';
import ButtonGoBack from '../../../../components/Buttons/ButtonGoBack';
import ButtonWithIcon from '../../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import Loading from '../../../../components/Loading/Loading';
import StaticImage from './components/StaticImage';
import ProductItemList from './ProductItemList';
import ContentProductEditImagesTab from './ContentProductEditImagesTab/ContentProductEditImagesTab';
import ContentProductEditResultModal from './ContentProductEditResultModal';
import RenderDecimalFormField from './components/RenderDecimalFormField';
import RenderWeightFormField from './components/RenderWeightFormField';
import RenderDateFormField from './components/RenderDateFormField';
import RenderSkuFormField from './components/RenderSkuFormField';
import RenderFormFieldDimensions from './components/RenderFormFieldDimensions';
import RenderProductStatusField from './components/RenderProductStatusField';
import RenderFormFieldTextArea from './components/RenderFormFieldTextArea';
import RenderFormFieldBrandsSelect from './components/RenderFormFieldBrandsSelect';
import RenderFormFieldFormMultipleSelect from './components/RenderFormFieldFormMultipleSelect';
import RenderFormFieldInput from './components/RenderFormFieldInput';
import ChangeCategoryButton from './components/ChangeCategoryButton';
import RenderFormFieldFormSelect from './components/RenderFormFieldFormSelect';
import ProductManagerField from '../ContentProducts/components/ContentProductsSidebar/ProductManagerField';
import NotificationNote from '../../../../components/Notifications/NotificationNote';
import TabsNavMenu from '../../../../containers/Tabs/TabsNavMenu';
import TabItemMenu from '../../../../components/Tabs/TabMenuItem/TabItemMenu';
import TabsNav from '../../../../containers/Tabs/TabsNav';
import TabsPanel from '../../../../containers/Tabs/TabsPanel';
import TabTitle from './components/TabTitle';
import ModalConfirm from '../../../../containers/Modals/ModalConfirm';
// Engine
import { pageLinks } from '../../../../../engine/config/routes';
import { asyncActions } from '../../../../../engine/core/content/saga/asyncActions';
import { locale } from '../../../../../engine/init/i18n';
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions as brandsAsyncActions } from '../../../../../engine/core/brands/saga/asyncActions';
import { accessList } from '../../../../../engine/config/access';
import { partnerAsyncAction } from '../../../../../engine/core/_partners/saga/asyncAction';
import { setWarningMessage } from '../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { userRoles } from '../../../../../engine/config/userRoles';
// Helpers
import { validators } from '../../../../../_helpers/validationsForm';
import { getDate } from '../../../../../_helpers/getDate';
import { tableFiltersTypes } from '../../../../../_helpers/data/pages/contentMenagement/contentProducts/tableFilters';
import { formName } from '../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
import { tableDataTypes } from '../../../../../_helpers/data/tableDataTypes';
import { withRouter } from '../../../../../_helpers/withRouter';
// Style
import ContentProductEditStyles from './ContentProductEditStyles';
import { SubTitle } from '../../styles';
import { COLOR } from '../../../../containers/App/AppStyles';
import { productGridEdit } from '../../../../../engine/core/content/selectors';

const tabs = Object.freeze({
  general: 'general',
  tech: 'tech',
  images: 'images',
  prices: 'prices',
  logistics: 'logistics',
});

const editFlags = Object.freeze({
  editable: 'editable',
  canChangeManager: 'canChangeManager',
  canChangeCategory: 'canChangeCategory',
  canEditProductLogistics: 'canEditProductLogistics',
  canCreatePhoto: 'canCreatePhoto',
  canEditPhoto: 'canEditPhoto',
  canDeletePhoto: 'canDeletePhoto',
});

export const getCheckboxName = name => (
  `${name}-checkbox`
);
export const inputValueEmpty = (value) => {
  const isEmptyString = value === '';
  const isEmptyObject = typeof value === 'object' && !value.size;
  const isEmptyArray = Array.isArray(value) && !value.length;
  return isEmptyString || isEmptyObject || isEmptyArray;
};

const getCurrentItemTitleLocale = (item, title, lang) => get(item, `${lang}`, title);

const requiredFieldsNames = {
  names: ['1', '2'],
  descriptions: ['6', '7'],
  quantities: ['quantity', 'feedQuantity'],
  prices: ['price', 'feedPrice', 'salePrice', 'feedSalePrice'],
};
const SELECT_FALSELY_VALUE = 0;
const cancelStatuseCodes = {
  emptyPhotoAndOrCharacteristics: 10,
  emptyCharacteristics: 16,
};

function mapStateToProps(state, ownProps) {
  const hashId = get(ownProps, 'router.params.hashId', '');

  return {
    hashId,
    cancelStatuses: selectors.allProductsTable.cancelStatuses(state),
    partnerId: selectors.user.userInfo(state).partnerId,
    isPartnerActiveRole: selectors.user.rolesList(state).includes(userRoles.partnerActive),
    hasProductEditAccess: selectors.user.accessList(state).includes(accessList.productEdit),
    hasProductCreateAccess: selectors.user.accessList(state).includes(accessList.productCreate),
    hasProductStatusEditAccess: selectors.user.accessList(state).includes(accessList.productStatusEdit),
    hasFeatureUpdateValuesFromMagentoAccess: selectors.user.accessList(state).includes(accessList.featureUpdateValuesFromMagento),
    isLoading: hashId ? selectors.allProductsTable.isProductByHashIdLoading(state) : false,
    productByHashId: selectors.allProductsTable.productByHashId(state),
    productGrid: productGridEdit(state),
    isProductGridLoading: selectors.content.productGridLoading(state),
    productStatusesFlow: selectors.productStatusesFlow(state),
    allProductStatuses: selectors.allProductsTable.productStatuses(state),
    sourceType: selectors.allProductsTable.productSource(state),
    productCreatePending: selectors.content.productCreatePending(state),
    productEditPending: selectors.allProductsTable.productEditPending(state),
    statusesFlow: selectors.allProductsTable.statusesFlow(state),
    selectedProducts: selectors.allProductsImmutableSelectionSku(state),
    createProductPending: selectors.allProductsTable.statusesFlow(state),
    allProducts: selectors.contentProduct.list(state),
    prevLocation: selectors.location.prevLocation(state),
    formValue: selectors.form.getFormValues(state, formName),
    language: localStorage.getItem('language'),
    postUsersHashIdPending: selectors.user.postUsersHashIdPending(state),
    hasAccessPartnerContextList: selectors.user.accessList(state).includes(accessList.partnerContextList),
    joyrideSteps: selectors.ui.joyrideSteps(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    putUpdateValuesFromMagentoAsync: featureId => dispatch(asyncActions.putUpdateValuesFromMagentoAsync(featureId)),
    changeFormFieldValue: (formField, fieldValue) => dispatch(change(formName, formField, fieldValue)),
    contentProductEditAsync: (data, redirectUrl, forceCallback, forceAction) => dispatch(asyncActions.contentProductEditAsync(data, redirectUrl, forceCallback, forceAction)),
    contentProductAddAsync: (data, redirectUrl) => dispatch(asyncActions.contentProductAddAsync(data, redirectUrl)),
    getBrandsListContextAsync: () => dispatch(brandsAsyncActions.getBrandsListsContextAsync()),
    getProductsHashIdAsync: hashId => dispatch(asyncActions.getProductsHashIdAsync(hashId)),
    getProductsStatusesAsync: forceUpdate => dispatch(asyncActions.getProductsStatusesAsync(forceUpdate)),
    getProductsSourceListAsync: () => dispatch(asyncActions.getProductsSourceListAsync()),
    getPartnerListAsync: () => dispatch(partnerAsyncAction.getPartnerContextListAsync()),
    touchField: field => dispatch(touch(formName, field)),
    updateTextarea: value => dispatch(change(formName, 'note', value)),
  };
}

class ContentProductEdit extends Component {
  static propTypes = {
    allEdit: PropTypes.bool,
    productEditPending: PropTypes.bool,
    cancelStatuses: PropTypes.instanceOf(List),
    categoryId: PropTypes.number,
    i18n: PropTypes.object,
    classes: PropTypes.object,
    partnerId: PropTypes.number,
    contentProductAddAsync: PropTypes.func,
    contentProductEditAsync: PropTypes.func,
    changeFormFieldValue: PropTypes.func,
    dispatch: PropTypes.func,
    getBrandsListContextAsync: PropTypes.func,
    getProductsStatusesAsync: PropTypes.func,
    getProductsSourceListAsync: PropTypes.func,
    getProductsHashIdAsync: PropTypes.func,
    hashId: PropTypes.string.isRequired,
    hasAccessPartnerContextList: PropTypes.bool,
    getPartnerListAsync: PropTypes.func,
    hasProductEditAccess: PropTypes.bool,
    hasProductStatusEditAccess: PropTypes.bool,
    isPartnerActiveRole: PropTypes.bool,
    hasProductCreateAccess: PropTypes.bool,
    hasFeatureUpdateValuesFromMagentoAccess: PropTypes.bool,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    isProductGridLoading: PropTypes.bool,
    massEdit: PropTypes.bool,
    postUsersHashIdPending: PropTypes.bool.isRequired,
    putUpdateValuesFromMagentoAsync: PropTypes.func,
    mode: PropTypes.oneOf(['create', 'edit']),
    language: PropTypes.oneOf(['ru', 'ua']).isRequired,
    pageTitle: PropTypes.string,
    pageSubTitle: PropTypes.string,
    productByHashId: PropTypes.instanceOf(Map),
    formValue: PropTypes.instanceOf(Map),
    productGrid: PropTypes.instanceOf(List),
    productStatus: PropTypes.instanceOf(List),
    sourceType: PropTypes.instanceOf(List),
    allProductStatuses: PropTypes.instanceOf(List),
    productStatusesFlow: PropTypes.instanceOf(Map),
    productCreatePending: PropTypes.bool,
    touchField: PropTypes.func,
    selectedProducts: PropTypes.instanceOf(List),
    t: PropTypes.func.isRequired,
    allProducts: PropTypes.instanceOf(List),
    joyrideSteps: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    updateTextarea: PropTypes.func,
  };

  static defaultProps = {
    allEdit: false,
    categoryId: null,
    mode: 'edit',
    classes: {},
    contentProductAddAsync: () => {
    },
    contentProductEditAsync: () => {
    },
    changeFormFieldValue: () => {
    },
    dispatch: () => {
    },
    getProductsHashIdAsync: () => {
    },
    handleSubmit: () => {
    },
    hasFeatureUpdateValuesFromMagentoAccess: false,
    isLoading: false,
    isProductGridLoading: false,
    massEdit: false,
    productCreatePending: false,
    pageSubTitle: '',
    allProducts: List(),
    productByHashId: Map(),
    productGrid: List(),
    productStatus: List(),
    productStatusesFlow: Map(),
    getBrandsListContextAsync: () => {
    },
    getProductsStatusesAsync: () => {
    },
    getProductsSourceListAsync: () => {
    },
    touchField: () => {
    },
    selectedProducts: List(),
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: tabs.general,
      isUserEditing: false,
      forceActionMessage: '',
      selectedCategoryValue: null,
    };
  }

  componentDidMount() {
    const {
      hasAccessPartnerContextList, getPartnerListAsync,
      mode, getBrandsListContextAsync, getProductsStatusesAsync, getProductsSourceListAsync,
    } = this.props;
    getBrandsListContextAsync();
    getProductsStatusesAsync();
    getProductsSourceListAsync();

    if (hasAccessPartnerContextList && mode === 'create') {
      getPartnerListAsync();
    }

    if (mode === 'edit') {
      this.getProductsByHashIdAsync();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      hashId, language, postUsersHashIdPending, productGrid,
      getProductsStatusesAsync, formValue,
    } = this.props;
    const { isUserEditing } = this.state;
    const lackOfCharacteristicsCancelStatuses = [cancelStatuseCodes.emptyPhotoAndOrCharacteristics, cancelStatuseCodes.emptyCharacteristics];
    const cancelStatusValue = formValue.getIn(['cancelStatus', 'value']);
    const isLackOfCharacteristicsCancelStatus = lackOfCharacteristicsCancelStatuses.includes(cancelStatusValue);

    if (
      prevProps.postUsersHashIdPending !== postUsersHashIdPending
      && postUsersHashIdPending === false
    ) {
      getProductsStatusesAsync({
        forceUpdate: true,
      });
    }

    if (hashId !== prevProps.hashId || language !== prevProps.language) {
      this.getProductsByHashIdAsync();
    }

    if (!isUserEditing && isLackOfCharacteristicsCancelStatus && prevProps.productGrid !== productGrid) {
      this.addEmptyRequiredFieldsToNote();
    }
  }

  addEmptyRequiredFieldsToNote = () => {
    const {
      formValue, t, productGrid, updateTextarea,
    } = this.props;

    const formFields = productGrid.toJS();
    const noteMessage = formValue.get('note');
    const cancelStatus = formValue.getIn(['cancelStatus', 'label']);
    const defaultRequiredFields = [
      { fieldName: requiredFieldsNames.names, label: t('Название') },
      { fieldName: requiredFieldsNames.descriptions, label: t('Описание') },
      { fieldName: requiredFieldsNames.quantities, label: t('Наличие') },
      { fieldName: requiredFieldsNames.prices, label: t('Стоимость') },
    ];

    const emptyFieldsNames = [];
    let emptyDefaultFieldsNames = [];
    const filledDefaultFieldsNames = [];

    formFields.forEach((field) => {
      const fieldValue = formValue.get(field.name);
      defaultRequiredFields.forEach(({ fieldName, label }) => {
        if (fieldName.includes(field.name) && (fieldValue === SELECT_FALSELY_VALUE || fieldValue)) {
          filledDefaultFieldsNames.push(label);
        }
      });

      emptyDefaultFieldsNames = defaultRequiredFields.map(item => item.label).filter(label => !filledDefaultFieldsNames.includes(label));

      if (!fieldValue && field.isRequiredLk === true) {
        emptyFieldsNames.push(t(field.title));
      }
    });

    emptyFieldsNames.push(...emptyDefaultFieldsNames);

    if (!emptyFieldsNames.length) {
      return;
    }
    const requiredFieldsLabel = `${t('He заполнено')}:`;
    const requiredFieldsString = emptyFieldsNames.length ? `${requiredFieldsLabel} ${(emptyFieldsNames.join(', '))}.` : '';
    const adminNoteRegEx = new RegExp(`${cancelStatus}.\n${requiredFieldsLabel}.*?\\n(.*)`, 's');
    const adminNote = noteMessage?.match(adminNoteRegEx)?.[1] || '';
    const updatedNote = `${cancelStatus}.\n${requiredFieldsString}\n${adminNote}`;

    updateTextarea(updatedNote);
  };

  handleUserEditing = () => {
    this.setState({ isUserEditing: true });
  };

  handleUserStopEditing = () => {
    this.setState({ isUserEditing: false });
  };

  getProductsByHashIdAsync = () => {
    const { hashId, getProductsHashIdAsync } = this.props;
    if (isString(hashId) && hashId.trim() !== '') {
      getProductsHashIdAsync(hashId);
    }
  };

  getProductsIds = () => {
    const {
      allEdit, massEdit, selectedProducts, productByHashId,
    } = this.props;

    switch (true) {
      case allEdit: {
        return 'all';
      }
      case massEdit: {
        return selectedProducts.toJS().map(({ id }) => id);
      }
      default: {
        return [productByHashId.get('id')];
      }
    }
  };

  getProductsValues = (values) => {
    const {
      productGrid, productByHashId, productStatusesFlow, mode,
    } = this.props;
    const productStatusValue = get(values.productStatus, 'value', values.productStatus);
    let additionalFields = [];

    if (!isNil(productStatusValue)) {
      const options = productStatusesFlow.get(productStatusValue.toString());
      const currentOption = find(options && options.toJS ? options.toJS() : options, { value: productStatusValue }) || { additionalFields: [] };

      if (currentOption.additionalFields) {
        additionalFields = currentOption.additionalFields;
      }
    }

    return [...productGrid.toJS(), ...additionalFields].reduce((acc, { name, type }) => {
      if (
        name === tableFiltersTypes.productStatus
        && typeof values[name] === 'number'
      ) {
        const productStatusWithCancelStatus = [5, 6, 7];
        if (productStatusWithCancelStatus.includes(values[name])) {
          acc[tableFiltersTypes.cancelStatus] = values[tableFiltersTypes.cancelStatus].value;
          acc[tableFiltersTypes.note] = values[tableFiltersTypes.note];
        }
      }
      if (
        name === tableFiltersTypes.manager
        && productByHashId.get(tableFiltersTypes.manager)?.value !== values[name]
        && values[name] !== null
      ) {
        acc[tableFiltersTypes.manager] = values[name];
      }
      if (name === tableFiltersTypes.productMedias) {
        const currentValue = (values[name] || []).map((imageItem) => {
          const { id, src: externalLink } = imageItem;
          const obj = { id, externalLink };
          if (obj.id === undefined) {
            delete obj.id;
          }
          return obj;
        });
        const oldValue = (productByHashId.toJS()[name] || []).map((imageItem) => {
          const { id, externalLink } = imageItem;
          const obj = { id, externalLink };
          if (obj.id === undefined) {
            delete obj.id;
          }
          return obj;
        });
        if ((!isEqual(currentValue, oldValue) && mode === 'edit') || mode === 'create') {
          acc[name] = currentValue;
          return acc;
        }
      }
      if (values[getCheckboxName(name)]) {
        const value = values[name];
        if (type === tableFiltersTypes.multipleSelect) {
          acc[name] = value.map(item => item.value);
        } else if (type === tableFiltersTypes.date) {
          acc[name] = value ? getDate(value, 'YYYY-MM-DD') : value;
        } else if (type === tableFiltersTypes.decimal && value !== undefined) {
          acc[name] = get(value, 'value', value).replace(/\s|,/ig, match => match === ',' ? '.' : '');
        } else if (type === tableFiltersTypes.price && value !== undefined) {
          acc[name] = get(value, 'value', value).replaceAll(',', '');
        } else {
          acc[name] = get(value, 'value', value);
        }
      }
      return acc;
    }, {});
  };

  onFormSubmit = (values, redirectUrl) => {
    const {
      categoryId, contentProductEditAsync, contentProductAddAsync, mode, t, partnerId,
      dispatch,
    } = this.props;
    const { forceActionMessage, selectedCategoryValue } = this.state;
    const valuesJS = values.toJS();

    const currentValue = this.getProductsValues(valuesJS);

    if (currentValue) {
      const priceFields = [
        tableDataTypes.price.name,
        tableDataTypes.feedPrice.name,
        tableDataTypes.salePrice.name,
        tableDataTypes.feedSalePrice.name,
      ];
      Object.keys(currentValue).forEach((key) => {
        if (currentValue[key] === undefined) {
          currentValue[key] = '';
        }
        if (priceFields.includes(key) && currentValue[key] !== undefined) {
          currentValue[key] = Number(currentValue[key]?.split(' ').join(''));
        }
      });
    }
    const data = {
      categoryId,
      productsValues: currentValue,
    };

    if (isEmpty(data.productsValues)) {
      const message = t('Нет данных для отправки');
      dispatch(setWarningMessage(message));
    } else if (mode === 'edit') {
      if (forceActionMessage && categoryId) {
        data.productsValues = { ...currentValue, category: selectedCategoryValue?.value };
      }
      data.products = this.getProductsIds();
      contentProductEditAsync(data, redirectUrl, this.setForceActionMessage, forceActionMessage);
    } else {
      const { productsValues: { idMagentoPartner, ...restData } } = data;
      const sendData = {
        categoryId,
        idMagentoPartner: partnerId || idMagentoPartner,
        productsValues: restData,
      };
      contentProductAddAsync(sendData, redirectUrl, this.setForceActionMessage, forceActionMessage);
    }
  };

  handleChangeTab = (ev, tab) => {
    this.setState({ selectedTab: tab });
  };

  shouldDisplayTab = (tab) => {
    const { selectedTab } = this.state;
    return selectedTab === tab ? 'flex' : 'none';
  };

  changeFieldValue = (fieldName, fieldValue) => {
    const { changeFormFieldValue } = this.props;
    changeFormFieldValue(fieldName, fieldValue);
  };

  setForceActionMessage = (message) => {
    this.setState({ forceActionMessage: message });
    if (!message) {
      setTimeout(() => window.location.reload(), 1000);
    }
  };

  setSelectedCategoryValue = (value) => {
    this.setState({ selectedCategoryValue: value });
  };

  handleForceActionCancel = () => {
    this.setState({ forceActionMessage: null });
  };

  handleForceActionConfirm = () => {
    const { dispatch } = this.props;
    dispatch(submit(formName));
  };

  getCurrentOptions = (name) => {
    const {
      cancelStatuses, productStatus, productByHashId, massEdit,
      productStatusesFlow, sourceType, allProductStatuses,
    } = this.props;
    const currentProductStatus = productByHashId.getIn([`${tableFiltersTypes.productStatus}`, 'value']);
    const productStatusesWithFlow = currentProductStatus ? productStatusesFlow.get(currentProductStatus.toString()) : productStatus;

    switch (name) {
      case tableFiltersTypes.productStatus: {
        return massEdit
          ? allProductStatuses.filter(item => item.canEdit)
          : productStatusesWithFlow;
      }
      case tableFiltersTypes.sourceType: {
        return sourceType;
      }
      default: {
        return cancelStatuses;
      }
    }
  };

  setTouchField = (name) => {
    const { touchField } = this.props;
    touchField(name);
  };

  checkIsFieldDisabled = (args) => {
    const { allEdit, productByHashId, massEdit } = this.props;
    const productEditable = productByHashId.get(editFlags.editable);

    const { bulkEditable, editable } = args;
    if (productEditable || productEditable === undefined) {
      switch (true) {
        case allEdit:
        case massEdit: {
          return !bulkEditable;
        }
        default: {
          return !editable;
        }
      }
    }

    return true;
  };

  checkFeatureDisabled = (args) => {
    const { name } = args;
    const { productByHashId } = this.props;
    const currentDisable = productByHashId.get('partnerDisabledFeatures')?.includes(name.toString());
    if (currentDisable) {
      return {
        showTooltip: true,
        disabled: productByHashId.get('disableFeatures'),
      };
    }
    return undefined;
  };

  renderProducts = (args) => {
    const { fields, tabName } = args;
    const {
      allEdit, massEdit, productByHashId, mode, t, formValue, hasProductStatusEditAccess,
      putUpdateValuesFromMagentoAsync, hasFeatureUpdateValuesFromMagentoAccess, isPartnerActiveRole, i18n,
      hasProductCreateAccess,
    } = this.props;
    const massProductEdit = allEdit || massEdit;

    return fields.toJS().map((field) => {
      const {
        bulkEditable, editable, name, code, title, type, required, isRequiredLk, isRequiredMg, values, onCreate, recommended, lang,
      } = field;
      const fieldGridParams = fields.toJS().find(element => element.name === name);
      const onRefresh = () => putUpdateValuesFromMagentoAsync({ featureId: name });
      //
      const isRequired = !allEdit && !massEdit && required;
      const disabled = this.checkIsFieldDisabled({ bulkEditable, editable });
      const featureDisabled = this.checkFeatureDisabled({ name });
      const hiddeInMassEdit = disabled && massProductEdit;
      const validate = isRequired ? [validators.required] : [];
      const productNotComplete = formValue.get('productNotComplete');
      const canEditProductLogistics = productByHashId.get(editFlags.canEditProductLogistics);
      const techFields = [
        tableDataTypes.contentManager.name,
        tableDataTypes.idMagentoPartner.name,
        tableDataTypes.productStatus.name,
        tableDataTypes.note.name,
        tableDataTypes.cancelStatus.name,
      ];
      const logisticsFields = [
        tableDataTypes.length.name,
        tableDataTypes.width.name,
        tableDataTypes.height.name,
        tableDataTypes.weight.name,
        tableDataTypes.ean.name,
      ];
      const priceFields = [
        tableDataTypes.price.name,
        tableDataTypes.feedPrice.name,
        tableDataTypes.salePrice.name,
        tableDataTypes.feedSalePrice.name,
        tableDataTypes.quantity.name,
        tableDataTypes.feedQuantity.name,
        tableDataTypes.priceAutoUpdate.name,
      ];
      const logisticsCodes = [
        'product_logistics_weight',
        'product_logistics_height',
        'product_logistics_length',
        'product_logistics_width',
      ];

      switch (tabName) {
        case tabs.logistics: {
          const productNotEditable = productByHashId.get(editFlags.editable) === false;
          const featureAutoUpdate = productByHashId.get(tableDataTypes.featureAutoUpdate.name);
          if (
            techFields.includes(name)
                  || priceFields.includes(name)
          ) {
            return null;
          }
          switch (code) {
            case 'product_logistics_length':
            case 'product_logistics_height':
            case 'product_logistics_width': {
              return (
                <Field
                  key={name}
                  featureDisabled={featureDisabled}
                  component={RenderFormFieldDimensions}
                  name={name.toString()}
                  id={name.toString()}
                  label={t(title)}
                  type="text"
                  margin="normal"
                  featureAutoUpdate={featureAutoUpdate}
                  isRecommended={recommended}
                  required={isRequiredLk}
                  disabled={productNotEditable}
                  massEdit={massProductEdit}
                  fieldFormat={type}
                  validate={validate}
                  changeFieldValue={this.changeFieldValue}
                  setTouchField={this.setTouchField}
                  canEditProductLogistics={canEditProductLogistics}
                />
              );
            }
            case 'product_logistics_weight': {
              return (
                <Field
                  key={name}
                  featureDisabled={featureDisabled}
                  component={RenderWeightFormField}
                  margin="normal"
                  featureAutoUpdate={featureAutoUpdate}
                  label={t(title)}
                  name={name.toString()}
                  isRecommended={recommended}
                  required={isRequiredLk}
                  disabled={productNotEditable}
                  massEdit={massProductEdit}
                  validate={validate}
                  changeFieldValue={this.changeFieldValue}
                  setTouchField={this.setTouchField}
                  canEditProductLogistics={canEditProductLogistics}
                />
              );
            }
            default: {
              return null;
            }
          }
        }
        case tabs.tech: {
          if (
            priceFields.includes(name)
            || hiddeInMassEdit
          ) {
            return null;
          }
          switch (name) {
            case tableDataTypes.contentManager.name: {
              const disabledManager =
                  (!formValue.get(editFlags.canChangeManager) && !massProductEdit)
                  || productByHashId.get(editFlags.editable) === false
                  || editable === false;
              return mode === 'create'
                ? null
                : (
                  <Fragment key={name}>
                    <Grid container sx={{ margin: '16px 0px 8px' }}>
                      <Grid item xs={1} />
                      <Grid item xs={10}>
                        <ProductManagerField
                          label={t('Менеджер контента')}
                          validate={validate}
                          disabled={disabledManager}
                        />
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <div />
                    {massProductEdit && <div />}
                  </Fragment>
                );
            }
            case tableDataTypes.idMagentoPartner.name: {
              if (massProductEdit) {
                return null;
              }
              const idMagentoPartner = formValue.get(tableDataTypes.idMagentoPartner.name);
              const inputProps = {
                value: idMagentoPartner,
              };
              return mode === 'create' && hasProductCreateAccess
                ? (
                  <Field
                    featureDisabled={featureDisabled}
                    component={RenderFormFieldFormSelect}
                    disabled={mode !== 'create'}
                    refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                    onRefresh={onRefresh}
                    formSelectWidth="100%"
                    key={name}
                    margin="normal"
                    variant="outlined"
                    label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                    name={name.toString()}
                    isRecommended={recommended || isRequiredMg}
                    isRequired={isRequiredLk}
                    massEdit={massProductEdit}
                    validate={validate}
                    mode={mode}
                    setTouchField={this.setTouchField}
                    changeFieldValue={this.changeFieldValue}
                    dataTestId="partnerMid"
                    searchDataTestId="searchMid"
                  />
                )
                : (
                  <Grid container sx={{ margin: '16px 0px 8px' }} key={name}>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        label={tableDataTypes.idMagentoPartner.title}
                        disabled
                        input={inputProps}
                      />
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                );
            }
            case tableDataTypes.productStatus.name:
            case tableDataTypes.cancelStatus.name:
            case tableDataTypes.note.name: {
              if (mode === 'create') {
                return null;
              }
              const options = this.getCurrentOptions(name);
              const actualOptions = massProductEdit
                ? options.filter(item => item.value !== 9)
                : options;
              return (
                <Field
                  productByHashIdSize={productByHashId.size}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  onFocus={this.handleUserEditing}
                  onBlur={this.handleUserStopEditing}
                  component={RenderProductStatusField}
                  noMargin
                  disabled={!hasProductStatusEditAccess || disabled || actualOptions?.size === 1}
                  formSelectWidth="100%"
                  key={name.toString()}
                  margin="normal"
                  variant="outlined"
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  name={name.toString()}
                  options={actualOptions}
                  isRecommended={recommended || isRequiredMg}
                  required={isRequiredLk}
                  touchField={this.setTouchField}
                  massEdit={massProductEdit}
                  checkIsFieldDisabled={this.checkIsFieldDisabled}
                  setTouchField={this.setTouchField}
                  changeFieldValue={this.changeFieldValue}
                  getCurrentOptions={this.getCurrentOptions}
                  fieldWrapperStyle={{ maxWidth: '100%' }}
                />
              );
            }
            default: {
              return null;
            }
          }
        }
        case tabs.prices: {
          if (
            techFields.includes(name)
            || logisticsFields.includes(name)
            || hiddeInMassEdit
            || logisticsCodes.includes(code)
          ) {
            return null;
          }
          switch (name) {
            case tableDataTypes.price.name:
            case tableDataTypes.feedPrice.name:
            case tableDataTypes.salePrice.name:
            case tableDataTypes.feedSalePrice.name: {
              return (
                <Field
                  featureDisabled={featureDisabled}
                  component={RenderFormFieldInput}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  productByHashIdSize={productByHashId.size}
                  disabled={disabled}
                  name={name.toString()}
                  id={name.toString()}
                  key={name.toString()}
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  type="text"
                  margin="normal"
                  isRecommended={recommended || isRequiredMg}
                  required={isRequiredLk}
                  massEdit={massProductEdit}
                  fullWidth
                  fieldFormat={type}
                  validate={validate}
                  changeFieldValue={this.changeFieldValue}
                  setTouchField={this.setTouchField}
                />
              );
            }
            case tableDataTypes.quantity.name:
            case tableDataTypes.priceAutoUpdate.name:
            case tableDataTypes.feedQuantity.name: {
              const isAutoField = name === tableDataTypes.priceAutoUpdate.name;
              const options = isAutoField
                ? [{ value: 0, label: t('Ручное') }, { value: 1, label: t('Авто') }]
                : [{ value: 0, label: t('Нет') }, { value: 1, label: t('Да') }];
              const disabledAuto = !formValue.get('canChangeAuto') && isAutoField;


              return (
                <Field
                  featureDisabled={featureDisabled}
                  component={RenderFormFieldFormSelect}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  productByHashIdSize={productByHashId.size}
                  disabled={disabled || disabledAuto}
                  formSelectWidth="100%"
                  key={name}
                  margin="normal"
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  name={name.toString()}
                  options={options}
                  isRecommended={recommended || isRequiredMg}
                  required={isRequiredLk}
                  massEdit={massProductEdit}
                  validate={validate}
                  mode={mode}
                  setTouchField={this.setTouchField}
                  changeFieldValue={this.changeFieldValue}
                />
              );
            }
            default: {
              return null;
            }
          }
        }
        default: {
          if (
            (mode === 'create' && !onCreate)
            || hiddeInMassEdit
            || techFields.includes(name)
            || logisticsFields.includes(name)
            || priceFields.includes(name)
            || logisticsCodes.includes(code)
          ) {
            return null;
          }
          switch (type) {
            case tableFiltersTypes.unsignedInt:
            case tableFiltersTypes.text: {
              const isPercentFiled = name === tableDataTypes.filedPercent.name;
              return (
                <Field
                  featureDisabled={featureDisabled}
                  component={RenderFormFieldInput}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  productByHashIdSize={productByHashId.size}
                  disabled={disabled}
                  name={name.toString()}
                  id={name.toString()}
                  key={name.toString()}
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  type="text"
                  margin="normal"
                  isRecommended={recommended || isRequiredMg}
                  required={isRequiredLk}
                  massEdit={massProductEdit}
                  fullWidth
                  fieldFormat={type}
                  validate={validate}
                  changeFieldValue={this.changeFieldValue}
                  setTouchField={this.setTouchField}
                  isFiledPercentWarning={isPercentFiled && productNotComplete && isPartnerActiveRole}
                  isPercentFiled={isPercentFiled}
                />
              );
            }
            case tableFiltersTypes.textArea: {
              return (
                <Field
                  featureDisabled={featureDisabled}
                  component={RenderFormFieldTextArea}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  productByHashIdSize={productByHashId.size}
                  disabled={disabled}
                  name={name.toString()}
                  id={name.toString()}
                  key={name.toString()}
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  type="text"
                  margin="normal"
                  fullWidth
                  isRecommended={recommended || isRequiredMg}
                  required={isRequiredLk}
                  massEdit={massProductEdit}
                  validate={validate}
                  changeFieldValue={this.changeFieldValue}
                  setTouchField={this.setTouchField}
                />
              );
            }
            case tableFiltersTypes.brand: {
              return (
                <Field
                  featureDisabled={featureDisabled}
                  component={RenderFormFieldBrandsSelect}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  productByHashIdSize={productByHashId.size}
                  disabled={disabled}
                  key={name.toString()}
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  name={name.toString()}
                  margin="normal"
                  isRecommended={recommended || isRequiredMg}
                  isRequired={isRequiredLk}
                  massEdit={massProductEdit}
                  validate={validate}
                  setTouchField={this.setTouchField}
                  changeFieldValue={this.changeFieldValue}
                />
              );
            }
            case tableFiltersTypes.select: {
              return (
                <Field
                  featureDisabled={featureDisabled}
                  component={RenderFormFieldFormSelect}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  productByHashIdSize={productByHashId.size}
                  disabled={disabled}
                  formSelectWidth="100%"
                  key={name.toString()}
                  margin="normal"
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  name={name.toString()}
                  options={values}
                  isRecommended={recommended || isRequiredMg}
                  required={isRequiredLk}
                  touchField={this.setTouchField}
                  massEdit={massProductEdit}
                  validate={validate}
                  mode={mode}
                  setTouchField={this.setTouchField}
                  changeFieldValue={this.changeFieldValue}
                />
              );
            }
            case tableFiltersTypes.sku: {
              const isDisabledSKU = this.checkIsFieldDisabled({
                bulkEditable,
                editable: mode === 'create' ? true : editable,
              });
              return (
                <Field
                  featureDisabled={featureDisabled}
                  component={RenderSkuFormField}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  productByHashIdSize={productByHashId.size}
                  disabled={isDisabledSKU}
                  name={name.toString()}
                  id={name.toString()}
                  key={name}
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  type="text"
                  margin="normal"
                  required={isRequiredLk}
                  isRecommended={recommended || isRequiredMg}
                  massEdit={massProductEdit}
                  fullWidth
                  mode={mode}
                  validate={validate}
                  productByHashId={productByHashId}
                  changeFieldValue={this.changeFieldValue}
                  setTouchField={this.setTouchField}
                />
              );
            }
            case tableFiltersTypes.multipleSelect: {
              return (
                <Field
                  featureDisabled={featureDisabled}
                  component={RenderFormFieldFormMultipleSelect}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  productByHashIdSize={productByHashId.size}
                  disabled={disabled}
                  formSelectWidth="100%"
                  key={name}
                  margin="normal"
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  name={name.toString()}
                  options={values}
                  isRecommended={recommended || isRequiredMg}
                  required={isRequiredLk}
                  massEdit={massProductEdit}
                  validate={validate}
                  productByHashId={productByHashId}
                  setTouchField={this.setTouchField}
                  changeFieldValue={this.changeFieldValue}
                />
              );
            }
            case tableFiltersTypes.boolean: {
              const isAutoField = name === tableDataTypes.featureAutoUpdate.name;
              const options = isAutoField
                ? [{ value: 0, label: t('Ручное') }, { value: 1, label: t('Авто') }]
                : [{ value: '0', label: t('Нет') }, { value: '1', label: t('Да') }];
              const disabledAuto = !formValue.get('canChangeAuto') && isAutoField;

              return (
                <Field
                  featureDisabled={featureDisabled}
                  component={RenderFormFieldFormSelect}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  productByHashIdSize={productByHashId.size}
                  disabled={disabled || disabledAuto}
                  formSelectWidth="100%"
                  key={name}
                  margin="normal"
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  name={name.toString()}
                  options={options}
                  isRecommended={recommended || isRequiredMg}
                  required={isRequiredLk}
                  massEdit={massProductEdit}
                  validate={validate}
                  mode={mode}
                  setTouchField={this.setTouchField}
                  changeFieldValue={this.changeFieldValue}
                />
              );
            }
            case tableFiltersTypes.datetime:
            case tableFiltersTypes.date: {
              return (
                <Field
                  featureDisabled={featureDisabled}
                  component={RenderDateFormField}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  productByHashIdSize={productByHashId.size}
                  disabled={disabled}
                  formSelectWidth="100%"
                  key={name}
                  margin="normal"
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  name={name.toString()}
                  isRecommended={recommended || isRequiredMg}
                  required={isRequiredLk}
                  massEdit={massProductEdit}
                  validate={validate}
                  productByHashId={productByHashId}
                  changeFieldValue={this.changeFieldValue}
                  setTouchField={this.setTouchField}
                />
              );
            }
            case tableFiltersTypes.decimal: {
              return (
                <Field
                  featureDisabled={featureDisabled}
                  component={RenderDecimalFormField}
                  refresh={fieldGridParams.canUpdate && hasFeatureUpdateValuesFromMagentoAccess}
                  onRefresh={onRefresh}
                  productByHashIdSize={productByHashId.size}
                  disabled={disabled}
                  key={name}
                  margin="normal"
                  label={getCurrentItemTitleLocale(lang, title, locale[i18n.language])}
                  name={name.toString()}
                  isRecommended={recommended || isRequiredMg}
                  required={isRequiredLk}
                  massEdit={massProductEdit}
                  validate={validate}
                  changeFieldValue={this.changeFieldValue}
                  setTouchField={this.setTouchField}
                />
              );
            }
            default: {
              return null;
            }
          }
        }
      }
    });
  };

  render() {
    const {
      allEdit, categoryId, classes, handleSubmit, isLoading, isProductGridLoading, massEdit, pageTitle, pageSubTitle,
      productGrid, t, productCreatePending, hashId, allProducts, productEditPending, formValue, i18n,
      productByHashId, isPartnerActiveRole, hasProductEditAccess, mode, joyrideSteps,
    } = this.props;

    const massProductEdit = allEdit || massEdit;
    const { selectedTab, forceActionMessage, selectedCategoryValue } = this.state;
    const price = formValue.get(tableDataTypes.price.name);
    const editable = productByHashId.get(editFlags.editable);
    const canChangeCategory = productByHashId.get(editFlags.canChangeCategory);
    const featureAutoUpdate = productByHashId.get(tableDataTypes.featureAutoUpdate.name);
    const canEditProductLogistics = productByHashId.get(editFlags.canEditProductLogistics);
    const canCreatePhoto = productByHashId.get(editFlags.canCreatePhoto) !== false;
    const canEditPhoto = productByHashId.get(editFlags.canEditPhoto) !== false;
    const canDeletePhoto = productByHashId.get(editFlags.canDeletePhoto) !== false;
    const feed = formValue.get(tableDataTypes.feed.name);
    const feedLocale = formValue.get(tableDataTypes.feedLocale.name);
    const sourceType = formValue.get(tableDataTypes.sourceType.name)?.get('label');
    const name = i18n.language === locale.ru ? formValue.get('1') : formValue.get('2');
    const salePrice = formValue.get(tableDataTypes.salePrice.name);
    const haveSalePrice = Boolean(salePrice && (salePrice?.replaceAll ? salePrice.replaceAll(' ', '') : salePrice >= 0));
    const firstImage = formValue.get(tableDataTypes.productMedias.name)?.[0]?.src;
    const firstImageAlloLink = formValue.get(tableDataTypes.productMedias.name)?.[0]?.alloLink;
    const pending = isLoading || isProductGridLoading;
    const productMedias = find(productGrid.toJS(), item => item.name === 'productMedias') || {
      bulkEditable: false,
      editable: false,
    };
    const productNotComplete = formValue.get('productNotComplete');

    return (
      <Form onSubmit={handleSubmit(this.onFormSubmit)}>
        <PageHeader title={pageTitle} subTitle={pageSubTitle}>
          <ButtonGoBack id="product_go_back_btn" defaultPage={pageLinks.content.products.all(categoryId)} />
          {!isNil(categoryId) && hasProductEditAccess && (
            <>
              <ButtonGoBack
                id="product_save_go_back_btn"
                disabled={productEditPending || productCreatePending}
                onSaveAndBackHandler={handleSubmit(value => this.onFormSubmit(value, pageLinks.content.products.all(categoryId)))}
              />
              {!massEdit && (
                <ButtonWithIcon
                  id="product_save_btn"
                  disabled={productEditPending || productCreatePending}
                  text={t('Сохранить')}
                  component="button"
                  onClick={handleSubmit(value => this.onFormSubmit(value, hashId ? pageLinks.content.products.edit(hashId) : undefined))}
                >
                  <SaveIcons />
                </ButtonWithIcon>
              )}
            </>
          )}
        </PageHeader>
        {pending
          ? (
            <Grid className={classes.tablePaper}>
              <Loading className={classes.fullHeight} isLoading />
            </Grid>
          )
          : (
            <>
              <NotificationNote type="info" component="div">
                {`${t('Красной звездой отмечены поля, обязательные для заполнения')}.
                 ${t('Синей звездой – рекомендуемые поля, по которым будет выполняться фильтрация на странице товаров')}.`}
              </NotificationNote>
              {productNotComplete && isPartnerActiveRole
                && (
                <NotificationNote type="warning" component="div">
                  {t('Внимание! Данный товар с низким количеством заполненных характеристик. Разместите характеристики для этого товара и сопоставьте их с характеристиками Алло')}.
                </NotificationNote>
                )
              }
              {
                (mode === 'create' && isPartnerActiveRole) || joyrideSteps
                  ? undefined
                  : (
                    <>
                      <Grid container className={classes.tabsContainer} sx={{ padding: '20px' }}>
                        <SubTitle
                          component="h3"
                          sx={{ marginBottom: '20px' }}
                        >
                          {t('Техническая информация')}
                        </SubTitle>
                        <Box className={classes.technicalInfo}>
                          <FieldArray
                            component={this.renderProducts}
                            fields={productGrid}
                            tabName={tabs.tech}
                            name="products"
                          />
                        </Box>
                      </Grid>
                      <br />
                    </>
                  )
              }
              <Grid container className={classes.tabsContainer}>
                <Grid item xs={12} md={4} lg={3} sx={{ borderRight: 1, borderColor: 'divider' }}>
                  <TabsNav customClasses={classes.tabsNavContentProductEdit} isCustomTemplate>
                    <Box sx={{ padding: '20px' }}>
                      <Box sx={{ display: 'flex' }}>
                        {!allEdit && !massEdit && (
                          firstImage
                            ? <StaticImage src={firstImage} alloLink={firstImageAlloLink} />
                            : (
                              <Paper
                                variant="outlined"
                                sx={{
                                  width: '100px',
                                  height: '100px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography
                                  sx={{ color: COLOR.black['38'] }}
                                  component="span"
                                >{t('Нет фото')}
                                </Typography>
                              </Paper>
                            )
                        )}
                        {
                        massProductEdit
                          ? (
                            <ProductItemList
                              allEdit={allEdit}
                              massEdit={massEdit}
                              categoryName={pageTitle}
                            />
                          )
                          : (
                            <Typography
                              sx={{
                                fontSize: '14px',
                                lineHeight: '20px',
                                marginLeft: '15px',
                              }}
                            >
                              {name}
                            </Typography>
                          )
                      }
                      </Box>
                      <Typography component="div" mt="10px">
                        {salePrice ? (
                          <Typography
                            component="span"
                            className={classes.price}
                          >
                            <NumberFormat value={salePrice} thousandSeparator=" " displayType="text" /> ₴
                          </Typography>
                        ) : null}
                        {price ? (
                          <Typography
                            sx={{ paddingLeft: haveSalePrice ? '10px' : '0' }}
                            component="span"
                            className={haveSalePrice ? classes.oldPrice : classes.price}
                          >
                            <NumberFormat value={price} thousandSeparator=" " displayType="text" /> ₴
                          </Typography>
                        ) : null}
                        <Typography component="hr" className={classes.contentHr} />
                        <Grid container>
                          <Grid item xs={6}><Typography className={classes.contentInfoName} component="span">{t('Категория')}:</Typography></Grid>
                          <Grid item xs={6} display="flex" alignItems="center">
                            <Typography className={classes.contentInfoValue} component="span">{pageTitle}</Typography>
                            {(editable || massEdit)
                            && (
                              <ChangeCategoryButton
                                products={this.getProductsIds()}
                                massEdit={massEdit}
                                canChangeCategory={massEdit ? true : canChangeCategory}
                                setForceActionMessage={this.setForceActionMessage}
                                forceAction={forceActionMessage}
                                selectedCategoryValue={selectedCategoryValue}
                                setSelectedCategoryValue={this.setSelectedCategoryValue}
                              />
                            )
                          }
                          </Grid>
                        </Grid>
                        {feed && (
                        <Grid container>
                          <Grid item xs={6}><Typography className={classes.contentInfoName} component="span">ID Прайса:</Typography></Grid>
                          <Grid item xs={6}><Typography className={classes.contentInfoValue} component="span">{feed}</Typography></Grid>
                        </Grid>
                        )}
                        {sourceType && (
                        <Grid container>
                          <Grid item xs={6}><Typography className={classes.contentInfoName} component="span">{t('Тип загрузки')}:</Typography></Grid>
                          <Grid item xs={6}><Typography className={classes.contentInfoValue} component="span">{sourceType}</Typography></Grid>
                        </Grid>
                        )}
                        {feedLocale && (
                        <Grid container>
                          <Grid item xs={6}><Typography className={classes.contentInfoName} component="span">{t('Язык')}:</Typography></Grid>
                          <Grid item xs={6}><Typography className={classes.contentInfoValue} component="span">{feedLocale === locale.ru ? t('Русский') : t('Украинский')}</Typography></Grid>
                        </Grid>
                        )}
                      </Typography>
                    </Box>
                    <Typography component="hr" sx={{ borderColor: 'divider' }} />

                    <TabsNavMenu
                      id="product_tabs_nav"
                      value={selectedTab}
                      onChange={this.handleChangeTab}
                    >
                      <TabItemMenu value={tabs.general} label={t('Основная информация')} />
                      <TabItemMenu value={tabs.prices} label={t('Цена и наличие')} />
                      {(!allEdit && !massEdit) && (
                        <TabItemMenu value={tabs.images} label={t('Редактирование Фото')} />
                      )}
                      <TabItemMenu value={tabs.logistics} label={t('Логистические данные')} />
                    </TabsNavMenu>
                  </TabsNav>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <TabsPanel
                    index={tabs.general}
                    value={selectedTab}
                    style={{ display: this.shouldDisplayTab(tabs.general) }}
                    isCustomTemplate
                  >
                    <Grid item xs={12}>
                      <div style={{ display: pending ? 'none' : 'block' }}>
                        <TabTitle title={t('Основная информация')} />
                        <FieldArray
                          component={this.renderProducts}
                          fields={productGrid}
                          tabName={tabs.general}
                          name="products"
                        />
                      </div>
                    </Grid>
                  </TabsPanel>
                  <TabsPanel
                    index={tabs.prices}
                    value={selectedTab}
                    style={{ display: this.shouldDisplayTab(tabs.prices) }}
                    isCustomTemplate
                  >
                    <Grid item xs={12}>
                      <div style={{ display: pending ? 'none' : 'block' }}>
                        <TabTitle title={t('Цена и наличие')} />
                        <FieldArray
                          component={this.renderProducts}
                          fields={productGrid}
                          tabName={tabs.prices}
                          name="products"
                        />
                      </div>
                    </Grid>
                  </TabsPanel>
                  <TabsPanel
                    index={tabs.logistics}
                    value={selectedTab}
                    style={{ display: this.shouldDisplayTab(tabs.logistics) }}
                    isCustomTemplate
                  >
                    <Grid item xs={12}>
                      <div style={{ display: pending ? 'none' : 'block' }}>
                        <TabTitle
                          title={t('Логистические данные')}
                          showLogisticsMessageEdit={(canEditProductLogistics === false && featureAutoUpdate) && !massEdit}
                          showLogisticsMessageMassEdit={massEdit}
                        />
                        <FieldArray
                          component={this.renderProducts}
                          fields={productGrid}
                          tabName={tabs.logistics}
                          name="products"
                        />
                      </div>
                    </Grid>
                  </TabsPanel>
                  <Field
                    component={ContentProductEditImagesTab}
                    disabled={this.checkIsFieldDisabled({
                      bulkEditable: productMedias.bulkEditable,
                      editable: productMedias.editable,
                    })}
                    canCreatePhoto={canCreatePhoto}
                    canEditPhoto={canEditPhoto}
                    canDeletePhoto={canDeletePhoto}
                    name={tableFiltersTypes.productMedias}
                    display={this.shouldDisplayTab(tabs.images)}
                    parentClasses={classes}
                    defaultValue={[]}
                    tabIndex={tabs.images}
                    tabValue={selectedTab}
                  />
                </Grid>
                {massEdit && (
                  <ContentProductEditResultModal allProductsTableItems={allProducts.toJS()} />
                )}
              </Grid>
            </>
          )}
        <ModalConfirm
          confirmText={t('Подтвердить')}
          textAlign="center"
          pendingConfirm={productEditPending}
          onCancel={this.handleForceActionCancel}
          onConfirm={this.handleForceActionConfirm}
          showModal={Boolean(forceActionMessage)}
          text={forceActionMessage}
          title={t('Внимание')}
        />
      </Form>
    );
  }
}

const reduxFormConfig = {
  form: formName,
  onSubmitFail: (errors) => {
    if (errors) {
      const firstError = Object.keys(errors)[0];
      const el = document.querySelector(`[id="${firstError}"]`) || document.querySelector(`[name="${firstError}"]`);
      if (el) {
        const position = el.getBoundingClientRect().top + document.documentElement.scrollTop;
        const offset = 70;
        document.querySelector('#main-wrapper').scrollTop = position - offset;
      }
    }
  },
};

export default compose(
  withStyles(ContentProductEditStyles),
  withTranslation(),
  withRouter,
  reduxForm(reduxFormConfig),
  connect(mapStateToProps, mapDispatchToProps),
)(ContentProductEdit);
