// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import localforage from 'localforage';
// Containers
import Typography from '@mui/material/Typography';
import Modal from '../../Modals/Modal/Modal';
// Engine
import { selectors } from '../../../../engine/config/selectors';
// Hooks
import { useChat } from '../../../hooks/useChat';
import { uiActions } from '../../../../engine/core/ui/action';
import { localData } from '../../../../engine/config/localData';

function OverlayNeedRefreshPage() {
  useChat();
  const shouldRenderRefresh = useSelector(selectors.ui.shouldRenderRefresh);
  const { t } = useTranslation();
  const {
    showOverlay, title, clearLocalForage,
  } = shouldRenderRefresh.toJS();
  const dispatch = useDispatch();

  const clearLastTimeShown = () => {
    localData.setItem(localData.keysObj.lastTimeRefreshPageShown, undefined);
  };

  const refreshPage = () => {
    if (clearLocalForage) {
      localforage.clear();
    }
    clearLastTimeShown();
    window.location.replace('/');
  };

  const handleIgnore = () => {
    dispatch(uiActions.mergeShouldRenderRefresh({ showOverlay: false }));
  };

  if (!showOverlay) {
    return null;
  }

  return (
    <Modal
      modalOpen={showOverlay}
      customModalToggle={handleIgnore}
      title={t(title)}
      handleModalSend={refreshPage}
      handleModalCancel={handleIgnore}
      modalMaxWidth="sm"
      buttonSendIcon={null}
      cancelText={t('refresh later')}
      sendText={t('Обновить')}
    >
      <Typography fontSize={14}>{t('Пожалуйста, перезагрузите страницу')}</Typography>
    </Modal>
  );
}

export default OverlayNeedRefreshPage;
