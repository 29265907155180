import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';

const ModalStyle = theme => ({
  marginRight2: {
    marginRight: theme.spacing(2),
  },
  modal: {
    overflowY: 'visible',
  },
  modalContent: {
    overflowY: 'visible',
  },
  marginTop2: {
    marginTop: theme.spacing(2),
  },
  labelTitle: {
    marginTop: theme.spacing(3),
    marginBottom: 0,
  },
  marginTop3: {
    marginTop: theme.spacing(3),
  },
  marginRightAuto: {
    marginRight: 'auto',
  },
  statusList: {
    margin: 0,
  },
  statusListMessage: {
    marginBottom: 8,
  },
  importError: {
    color: red[500],
  },
  importSuccess: {
    color: green[500],
  },
  textCenter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  skuContainer: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(-0.25),
    marginRight: theme.spacing(-0.25),
    marginBottom: theme.spacing(2),
  },
  sku: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    marginBottom: theme.spacing(1),
  },
  dialogContentHolder: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
});

export default ModalStyle;
