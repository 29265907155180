import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isImmutable } from 'immutable';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import ContentWrapper from '../../../containers/ContentWrapper/ContentWrapper';
import Loading from '../../../components/Loading/Loading';
import { selectors } from '../../../../engine/config/selectors';
import { getDateWithoutHours } from '../../../../_helpers/getDate';
import OrderInfo from './OrderInfo';
import TTNForm from './TTNForm';
import FullScreenModal from '../../../containers/Modals/FullScreenModal/FullScreenModal';
import { api } from '../../../../engine/config/api';
import { AlertModalDialog } from '../../ShippingIntegration/components/AlertModalDialog';
import { setErrorMessage, setSuccessMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { getDefaultFormValues } from '../_helpers/alloExpressUtils';

let initialValues = {};
export default function AlloExpressTTNModal({ open, onClose, alloExpressTtnExists }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const orderItemByHashId = useSelector(selectors.orders.orderItemByHashId);
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [sendDataInProgress, setSendDataInProgress] = useState(false);
  const [alloExpressInfo, setAlloExpressInfo] = useState({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const orderData = useMemo(() => {
    const normalizeOrderItemByHashId = isImmutable(orderItemByHashId) ? orderItemByHashId.toJS() : {};
    return {
      hashId: get(normalizeOrderItemByHashId, 'item.hashId'),
      orderNumber: get(normalizeOrderItemByHashId, 'item.idMagentoOrder', ''),
      orderDate: getDateWithoutHours(get(normalizeOrderItemByHashId, 'item.orderedAt')),
      customerName: get(normalizeOrderItemByHashId, 'item.customerName', ''),
      customerPhone: get(normalizeOrderItemByHashId, 'item.customerPhone', ''),
      shippingCity: get(normalizeOrderItemByHashId, 'item.shippingCity', ''),
      regionName: get(normalizeOrderItemByHashId, 'item.regionName', ''),
      shippingStreet: get(normalizeOrderItemByHashId, 'item.shippingStreet', ''),
      shippingHouse: get(normalizeOrderItemByHashId, 'item.shippingHouse', ''),
      shippingApartment: get(normalizeOrderItemByHashId, 'item.shippingApartment', ''),
      stockName: get(normalizeOrderItemByHashId, 'item.stockName', ''),
      orderItems: get(normalizeOrderItemByHashId, 'item.orderItems', []),
      amount: get(normalizeOrderItemByHashId, 'item.amount'),
    };
  }, [orderItemByHashId]);

  const [formValues, setFormValues] = useState(getDefaultFormValues(orderData));

  const getAlloExpressInfoData = async () => {
    try {
      setPageIsLoading(true);
      const infoResponse = await api.alloExpress.getAlloExpressInfo(orderData.hashId);
      setAlloExpressInfo(infoResponse.data);
      if (alloExpressTtnExists) {
        const ttnResponse = await api.alloExpress.getAlloExpressTtn(orderData.hashId);
        const { ttn } = ttnResponse.data;
        initialValues = cloneDeep(ttn);
        setFormValues(prevValues => ({
          ...prevValues,
          ...ttn,
        }));
      }
    } finally {
      setPageIsLoading(false);
    }
  };
  const handleOnCloseConfirmOpen = () => {
    setConfirmDialogOpen(true);
  };
  const handleConfirmSuccess = () => {
    setConfirmDialogOpen(false);
    onClose();
  };
  const handleConfirmCancel = () => {
    setConfirmDialogOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setSendDataInProgress(true);
      let response;
      if (!alloExpressTtnExists) {
        response = await api.alloExpress.createAlloExpressTtn(orderData.hashId, formValues);
      } else {
        response = await api.alloExpress.updateAlloExpressTtn(orderData.hashId, formValues);
      }
      if (response.status > 200) {
        dispatch(setErrorMessage(response.data.status, t('Ошибка')));
      } else {
        dispatch(setSuccessMessage(response.data.status, t('Успех')));
        onClose();
      }
    } finally {
      setSendDataInProgress(false);
    }
  };

  useEffect(() => {
    if (open) {
      getAlloExpressInfoData();
    }
  }, [open, orderItemByHashId, alloExpressTtnExists]);

  if (pageIsLoading) {
    return (
      <FullScreenModal
        open={open}
        onClose={onClose}
        fullScreen
        scroll={'body'}
        margin={0}
      >
        <Box sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
        }}
        >
          <Loading isLoading={pageIsLoading} />
        </Box>
      </FullScreenModal>
    );
  }

  return (
    <FullScreenModal
      open={open}
      onClose={handleOnCloseConfirmOpen}
      fullScreen
      scroll={'body'}
      margin={0}
    >
      <Box
        borderBottom={'1px solid rgba(48, 48, 48, 0.12)'}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={1}
        padding={2}
        height={52}
      >
        {alloExpressTtnExists ? (<Typography color={'#303030'} fontWeight={700} fontSize={16} gutterBottom>Редагувати ТТН</Typography>) : (<Typography color={'#303030'} fontWeight={700} fontSize={16} gutterBottom>Створення ТТН</Typography>)}
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleOnCloseConfirmOpen}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <ContentWrapper sx={{ marginBottom: '10px' }}>
        <Container maxWidth="lg" sx={{ width: '1302px' }}>
          <Grid
            container
            justifyContent="center"
            spacing={1}
            sx={{
              mt: '10px', pr: '20px', pl: '20px', btlr: '6px !important', height: '92%',
            }}
          >
            <Grid item xs={4}>
              <OrderInfo orderData={orderData} />
            </Grid>
            <Grid item xs={8}>
              <TTNForm
                handleOnCloseConfirmOpen={handleOnCloseConfirmOpen}
                alloExpressTtnExists={alloExpressTtnExists}
                sendDataInProgress={sendDataInProgress}
                handleSubmit={handleSubmit}
                alloExpressInfo={alloExpressInfo}
                initialValues={initialValues}
                setFormValues={setFormValues}
                formValues={formValues}
                orderAmount={orderData.amount}
              />
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Container>
      </ContentWrapper>
      <AlertModalDialog
        open={confirmDialogOpen}
        handleConfirmCancel={handleConfirmCancel}
        handleConfirmSuccess={handleConfirmSuccess}
      />
    </FullScreenModal>
  );
}
