// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Parts
import Modal from '../../../../../../containers/Modals/Modal/Modal';
import ImportResultXlsxBlock from './ImportResultXlsxBlock';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions as contentTemplateAction } from '../../../../../../../engine/core/contentTemplates/actions';


function ImportResultModal() {
  const contentTemplates = useSelector(selectors.contentTemplatesImport.importModal);
  const contentTemplateStatus = contentTemplates.get('status');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleModalToggle = () => {
    dispatch(contentTemplateAction.setContentTemplateImportModalInitialState());
  };

  return (
    <Modal
      modalOpen={Boolean(contentTemplateStatus)}
      modalMaxWidth="md"
      onClose={handleModalToggle}
      handleModalToggle={handleModalToggle}
      handleModalCancel={handleModalToggle}
      title={t('Результаты импорта')}
      hideButtons
    >
      {contentTemplateStatus && <ImportResultXlsxBlock />}
    </Modal>
  );
}

export default ImportResultModal;
