// Core
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// UI
import Grid from '@mui/material/Grid';
// Containers
import PageHeader from '../../../../components/PageHeader/PageHeader';
// Components
import AddFormXlsx from './components/AddFormXlsx';
import AddFormXml from './components/AddFormXml';
import ImportTemplate from './components/ImportTemplate';
import InfoSidebar from './components/InfoSidebar';
import ImportPartnerDataInfo from './components/ImportPartnerDataInfo';
// Engine
import { uiActions } from '../../../../../engine/core/ui/action';
import { selectors } from '../../../../../engine/config/selectors';
import { userRoles } from '../../../../../engine/config/userRoles';
// Hooks
import { useUserRole } from '../../../../hooks/useUserRole';
import ImportResultModal from './components/ImportResult/ImportResultModal';

function ImportPage() {
  const { t } = useTranslation();
  const type = useSelector(selectors.ui.importPageType);
  const isPartnerRoleDisabled = useUserRole(userRoles.partnerDisabled);
  const dispatch = useDispatch();

  const handleChangeType = (event) => {
    dispatch(uiActions.setImportPageType(event.target.value));
  };

  return (
    <>
      <PageHeader pageHeadingClassName="importPageHeader" title={t('Импорт')} />
      { isPartnerRoleDisabled ? (
        <ImportPartnerDataInfo />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <ImportTemplate handleChangeType={handleChangeType} type={type} />
            { type === 'XML' ? <AddFormXml /> : <AddFormXlsx /> }
            <ImportResultModal />
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoSidebar />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default memo(ImportPage);
